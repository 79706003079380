import * as React from 'react';

import { Panel } from 'primereact/panel';
import { DataTable, DataTableFilterMetaData } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Splitter, SplitterPanel } from 'primereact/splitter'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { TabView, TabPanel } from 'primereact/tabview'
import { Tooltip } from 'primereact/tooltip';
import { Checkbox } from 'primereact/checkbox';

import useAlert from '@app/Context/Alert';
import useAuth from '@app/Context/Auth';
import { AccelerateAPI } from "@app/utils/api";
import { IAPISite } from '@app/interfaces';
import { IAPICredentialSSH, IAPICredentialInfra } from '@app/interfaces';
import stylePaths from 'stylePaths';

const Sites: React.FunctionComponent = () => {
  const { token } = useAuth();
  const { showAPIErrorAlert } = useAlert();
  const [sites, setSites] = React.useState<IAPISite[]>([]);
  const [siteImportVisible, setSiteImportVisible] = React.useState(false);
  const [siteInfoVisible, setSiteInfoVisible] = React.useState(false);
  const [siteDeleteVisible, setSiteDeleteVisible] = React.useState(false);
  const [editVisible, setEditVisible] = React.useState(false);
  const [infraSelected, setInfraSelected] = React.useState(null);
  const [sshSelected, setSSHSelected] = React.useState(null);
  const [siteSelected, setSiteSelected] = React.useState(null);
  const [siteFilter, setSiteFilter] = React.useState<DataTableFilterMetaData>({
    value: '',
    matchMode: FilterMatchMode.CONTAINS
  });
  const [siteFilterValue, setSiteFilterValue] = React.useState('');
  const api = new AccelerateAPI(token!);

  const [sshCreds, setSSHCreds] = React.useState<IAPICredentialSSH[]>([]);
  const [infraCreds, setInfraCreds] = React.useState<IAPICredentialInfra[]>([]);
  const [sshResults, setSSHResults] = React.useState<any>([]);
  const [infraResults, setInfraResults] = React.useState<any>([]);
  const [siteResults, setSiteResults] = React.useState<any>([]);
  const [sshNames, setSSHNames] = React.useState<any>([]);
  const [infraNames, setInfraNames] = React.useState<any>([]);
  const [siteNames, setSiteNames] = React.useState<any>([]);
  const [tasksRunning, setTasksRunning] = React.useState(Object());

  const [tasks, setTasks] = React.useState<any>([]);
  const [force, setForce] = React.useState(false);
  const [name, setName] = React.useState(null);
  const [activeId, setActiveId] = React.useState<string>("");
  const [activeSite, setActiveSite] = React.useState<IAPISite>({
    id: "",
    owner: "",
    name: "",
    description: "",
    site: {
      type: "",
    },
    state: {
      status: "",
      inventory: {},
      location: "",
      protected: false,
      self: false
    },
    created: "",
    updated: ""
  });

  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const locations = [
    'Local',
    'Remote'
  ];


  // Initial load
  React.useEffect(() => {
    fetchSites();
    setInterval(async () => fetchSites(), 10000)
  }, [token]);

  function fetchSites() {
    api.listCredentialsSSH().then(res => res.data).then(setSSHCreds)
      .catch(err => showAPIErrorAlert(err, "Unable to fetch Credentials (SSH)"));
    api.listCredentialsInfra().then(res => res.data).then(setInfraCreds)
      .catch(err => showAPIErrorAlert(err, "Unable to fetch Credentials (Infra)"));
    api.listSites().then(res => {
      setSites(res.data);
      checkTasksRunning(res.data);
    }).catch(err => showAPIErrorAlert(err, "Unable to fetch Sites"));
    api.checkToken();
    clear();
  }

  for (let index = 0; index < sshCreds.length; index++) {
    if (!sshNames.includes(sshCreds[index].name)) {
      setSSHNames([
        ...sshNames,
        sshCreds[index].name
      ]);
      setSSHResults([
        ...sshResults,
        sshCreds[index].id
      ]);
    }
  }
  for (let index = 0; index < infraCreds.length; index++) {
    if (!infraNames.includes(infraCreds[index].name)) {
      setInfraNames([
        ...infraNames,
        infraCreds[index].name
      ]);
      setInfraResults([
        ...infraResults,
        infraCreds[index].id
      ]);
    }
  }
  for (let index = 0; index < sites.length; index++) {
    if (!siteNames.includes(sites[index].name)) {
      setSiteNames([
        ...siteNames,
        sites[index].name
      ]);
      setSiteResults([
        ...siteResults,
        sites[index].id
      ]);
    }
  }

  for (let index = 0; index < sites.length; index++) {
    if (!Object.keys(tasksRunning).includes(sites[index].id)){
      tasksRunning[sites[index].id] = false;
    }
  }

  function findIdFromName(list1: any, list2: any, name: string) {
    for (let index = 0; index < list1.length; index++) {
      if (name == list1[index])
        return list2[index];
    }
  }

  function clear() {
    setSiteNames([])
    setSiteResults([])
  }

  function checkTasksRunning(siteList = Array<IAPISite>()) {
    for (let i = 0; i < siteList.length; i++){
      api.tasksRunning(siteList[i].id).then(res => {
        tasksRunning[siteList[i].id] = res.data;
      }).catch(err => showAPIErrorAlert(err, "Unable to check if any tasks are running for site " + siteList[i].id));
    }
  }

  const onSiteFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSiteFilter({ ...siteFilter, value: value });
    setSiteFilterValue(value);
  }

  const customFileUploader = async (event: FileUploadHandlerEvent) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();

    if (file.name.endsWith(".yaml")) {
      // console.log("uploaded file is .yaml");

      reader.readAsText(file);

      reader.onloadend = function () {
        const yaml = require('yaml');
        const fileData = JSON.stringify(yaml.parse(reader.result), null, 2);
        // console.log('fileData', fileData);

        if (typeof fileData === 'string') {
          // console.log('object', JSON.parse(fileData));

          if (selectedLocation == 'Local') {
            console.log("local site");
            createLocalSite(JSON.parse(fileData));
          } else if (selectedLocation == 'Remote') {
            console.log("remote site");
            createRemoteSite(JSON.parse(fileData));
          } else {
            createSite(JSON.parse(fileData));
          }

        } else {
          console.error('Unable to parse base64 data');
        }
      };

    } else if (file.name.endsWith(".json")) {
      // console.log("uploaded file is .json");

      reader.readAsText(file);

      reader.onloadend = function () {
        const fileData = reader.result;
        // console.log('fileData', fileData);

        if (typeof fileData === 'string') {
          // console.log('object', JSON.parse(fileData));

          if (selectedLocation == 'Local') {
            console.log("local site");
            createLocalSite(JSON.parse(fileData));
          } else if (selectedLocation == 'Remote') {
            console.log("remote site");
            createRemoteSite(JSON.parse(fileData));
          } else {
            createSite(JSON.parse(fileData));
          }

        } else {
          console.error('Unable to parse base64 data');
        }
      };
    }
  };

  function checkTerraform(fileData: any = null) {
    const yaml = require('yaml')
    if (fileData) {
      let result = yaml.parse(fileData)
      if (result === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  function createSite(siteData: any) {
    console.log('createSite', siteData);

    api.createSite(siteData).then(res => {
      console.log('createSite', res);
      fetchSites();
      setSiteImportVisible(false);
    }).catch(err => {
      showAPIErrorAlert(err, "Unable to create site")
    });
  }

  function createLocalSite(siteData: any) {
    console.log('createSite', siteData);

    api.createLocalSite(siteData).then(res => {
      console.log('createSite', res);
      fetchSites();
      setSiteImportVisible(false);
    }).catch(err => showAPIErrorAlert(err, "Unable to create site"));
  }

  function createRemoteSite(siteData: any) {
    console.log('createSite', siteData);

    api.createRemoteSite(siteData).then(res => {
      console.log('createSite', res);
      fetchSites();
      setSiteImportVisible(false);
    }).catch(err => showAPIErrorAlert(err, "Unable to create site"));
  }

  const getRemoteFile = (id) => {

    const FileDownload = require("js-file-download");

    api.getRemoteFile(id).then(({ data }) => {
      const yaml = require('yaml');
      const filename = data['name'] + '_remote.yaml';

      FileDownload(yaml.stringify(data), filename);
      console.log("success!", data);
    }).catch(err => showAPIErrorAlert(err, "Unable to get remote file"));
  };

  const createSiteDialog = () => {
    return (
      <Dialog visible={siteImportVisible} modal
        style={{ width: '50rem' }}
        footer={
          <div>
            <Button label="Cancel" icon="pi pi-times"
              onClick={() => setSiteImportVisible(false)}
              className="p-button-text" />
          </div>
        }
        onHide={() => setSiteImportVisible(false)}>
        <p className="m-0">
          Using this form, you can import a site into the system.
        </p>
        <br />
        <div className="p-inputgroup flex-1">
          <FileUpload
            mode="basic"
            name="demo[]"
            accept=".json, .yaml"
            maxFileSize={1000000}
            customUpload
            uploadHandler={customFileUploader} />
        </div>
        <br />
        <div className="p-inputgroup flex-1">
          <Dropdown
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.value)}
            options={locations}
            showClear
            placeholder="Site Location"
            className="w-full md:w-14rem" />
        </div>
      </Dialog>
    )
  }

  const renderSiteHeader = () => {
    return (
      <>
        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText value={siteFilterValue}
              onChange={onSiteFilterChange}
              placeholder=" Keyword Search" />
          </span>
          <Button size='small'
            className="p-button-primary"
            onClick={() => { setSiteImportVisible(true), setSelectedLocation(null) }}>
            Import
          </Button>
        </div>
      </>
    );
  }

  const confirmSiteDelete = (id) => {
    confirmDialog({
      message: 'Do you want to delete this site?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => {
        api.deleteSite(id, force).then(fetchSites)
          .catch(err => showAPIErrorAlert(err, "Unable to delete site"));
        setSiteDeleteVisible(false);
        setActiveSite({
          id: "",
          owner: "",
          name: "",
          description: "",
          site: {
            type: "",
          },
          state: {
            status: "",
            inventory: {},
            location: "",
            protected: false,
            self: false
          },
          created: "",
          updated: ""
        })
      },
      reject: () => { }
    });
  };

  const confirmSiteDestroy = (id) => {
    confirmDialog({
      message: 'Do you want to destroy this site?',
      header: 'Destroy Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => {
        api.destroySite(id).then(fetchSites)
          .catch(err => showAPIErrorAlert(err, "Unable to destroy site"));
        setEditVisible(false);
        setActiveSite({
          id: "",
          owner: "",
          name: "",
          description: "",
          site: {
            type: "",
          },
          state: {
            status: "",
            inventory: {},
            location: "",
            protected: false,
            self: false
          },
          created: "",
          updated: ""
        })
      },
      reject: () => { }
    });
  };

  const confirmSiteDeploy = (id, infra, ssh) => {
    let infraID = findIdFromName(infraNames, infraResults, infra)
    let sshID = findIdFromName(sshNames, sshResults, ssh)
    confirmDialog({
      message: 'Do you want to deploy this site?',
      header: 'Deploy Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-success',
      accept: () => {
        api.deploySite(id, infraID, sshID).then(fetchSites)
          .catch(err => showAPIErrorAlert(err, "Unable to deploy site"));
        setEditVisible(false);
        setActiveSite({
          id: "",
          owner: "",
          name: "",
          description: "",
          site: {
            type: "",
          },
          state: {
            status: "",
            inventory: {},
            location: "",
            protected: false,
            self: false
          },
          created: "",
          updated: ""
        })
      },
      reject: () => { }
    });
  };

  const confirmSitePrepare = (id, ssh) => {
    let sshID = findIdFromName(sshNames, sshResults, ssh)
    confirmDialog({
      message: 'Do you want to prepare this site?',
      header: 'Prepare Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-primary',
      accept: () => {
        api.prepareSite(id, sshID).then(fetchSites)
          .catch(err => showAPIErrorAlert(err, "Unable to prepare site"));
        setEditVisible(false);
        setActiveSite({
          id: "",
          owner: "",
          name: "",
          description: "",
          site: {
            type: "",
          },
          state: {
            status: "",
            inventory: {},
            location: "",
            protected: false,
            self: false
          },
          created: "",
          updated: ""
        })
      },
      reject: () => { }
    });
  };

  const confirmSiteBridge = (id, site2, ssh) => {
    let siteID = findIdFromName(siteNames, siteResults, site2)
    let sshID = findIdFromName(sshNames, sshResults, ssh)
    confirmDialog({
      message: 'Do you want to bridge to ' + site2 + '?',
      header: 'Bridge Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-primary',
      accept: () => {
        api.bridgeSite(id, siteID, sshID).then(fetchSites)
          .catch(err => showAPIErrorAlert(err, "Unable to complete bridge"));
        setEditVisible(false);
        setActiveSite({
          id: "",
          owner: "",
          name: "",
          description: "",
          site: {
            type: "",
          },
          state: {
            status: "",
            inventory: {},
            location: "",
            protected: false,
            self: false
          },
          created: "",
          updated: ""
        })
      },
      reject: () => { }
    });
  };

  function getTasks() {
    api.listTasks(activeSite.id).then(res => res.data).then(setTasks)
      .catch(err => showAPIErrorAlert(err, "Unable to fetch tasks"));
  }

  const displayDelete = () => {
    return (
      <Dialog visible={siteDeleteVisible}
        modal
        header={name}
        onHide={() => {
          setSiteDeleteVisible(false);
          setActiveSite({
            id: "",
            owner: "",
            name: "",
            description: "",
            site: {
              type: "",
            },
            state: {
              status: "",
              inventory: {},
              location: "",
              protected: false,
              self: false
            },
            created: "",
            updated: ""
          });
        }}
        footer={
          <>
            <Button label="Cancel"
              className='w-full md:w-5rem'
              size='small'
              outlined
              onClick={() => { setSiteDeleteVisible(false); }}
            ></Button>
            <Button label="Continue"
              className='w-full md:w-6rem'
              size='small'
              severity='danger'
              // disabled={
              //   activeSite.state.status !== 'initialized' &&
              //   force !== true
              // }
              onClick={() => { confirmSiteDelete(activeSite.id) }}
            ></Button>
          </>
        }>
        <p>
          If site is in a running state, delete must be forced.
        </p>
        <Checkbox inputId="force" name="force" onChange={() => setForce(!force)} checked={force} disabled={activeSite.state.status === 'initialized'}></Checkbox>
        <label htmlFor="force" className='ml-2'>Force</label>
      </Dialog>
    )
  }

  const displayInfo = () => {
    const yaml = require('yaml')

    const site_lines = yaml.stringify(activeSite.site).split('\n');
    let site = ''

    for (let i = 0; i < site_lines.length; i++) {
      if (!site_lines[i].includes('null')) {
        site = site + site_lines[i] + '\n'
      }
    }

    let inventory = (yaml.stringify(activeSite.state.inventory));
    let taskList = (yaml.stringify(tasks));

    return (
      <Dialog visible={siteInfoVisible}
        modal
        header={name}
        style={{ width: '50rem', height: '50rem' }}
        onHide={() => {
          setSiteInfoVisible(false);
          setActiveSite({
            id: "",
            owner: "",
            name: "",
            description: "",
            site: {
              type: "",
            },
            state: {
              status: "",
              inventory: {},
              location: "",
              protected: false,
              self: false
            },
            created: "",
            updated: ""
          });
          setTasks([])
        }}>
        <p>
          <Button label="Get Remote Config"
            className="w-full md:w-14rem"
            severity="success"
            style={{ width: 150 }}
            disabled={
              activeSite.state.inventory == null
            }
            onClick={() => getRemoteFile(activeSite.id)} />
        </p>
        <TabView>
          <TabPanel header="Info">
            <p className="m-0">
              UUID: {activeSite.id}
            </p>
            <p className="m-0">
              Description: {activeSite.description}
            </p>
            <p className="m-0">
              Protected: {String(activeSite.state.protected)}
            </p>
            <p className="m-0">
              Created: {activeSite.created}
            </p>
            <p className="m-0">
              Updated: {activeSite.updated}
            </p>
          </TabPanel>
          <TabPanel header="Site">
            <pre>
              {site}
            </pre>
          </TabPanel>
          <TabPanel header="Inventory">
            <pre>
              {inventory}
            </pre>
          </TabPanel>
          <TabPanel header="Tasks">
            <Button text icon="pi pi-refresh" size='small'
              className="p-button-info"
              severity="info"
              onClick={getTasks} />
            <pre>
              {taskList}
            </pre>
          </TabPanel>
        </TabView>
      </Dialog >
    );
  };

  const displaySite = () => {
    return (
      <Dialog visible={editVisible} modal
        header={name}
        onHide={() => {
          setEditVisible(false); setActiveSite({
            id: "",
            owner: "",
            name: "",
            description: "",
            site: {
              type: "",
            },
            state: {
              status: "",
              inventory: {},
              location: "",
              protected: false,
              self: false
            },
            created: "",
            updated: ""
          })
        }}>
        <Splitter>
          <SplitterPanel className="flex align-items-center justify-content-center">
            <body>
              <p>
                <Button label="Deploy Site"
                  className="w-full md:w-14rem"
                  severity="success"
                  style={{ width: 150 }}
                  disabled={
                    activeSite.state.status !== 'initialized' ||
                    activeSite.state.location === 'remote' ||
                    activeSite.state.protected === true
                  }
                  onClick={() => confirmSiteDeploy(activeSite.id, infraSelected, sshSelected)} />
              </p>
              <p>
                <Button label="Prepare Site"
                  className="w-full md:w-14rem"
                  style={{ width: 150 }}
                  disabled={
                    activeSite.state.status === 'prepared' ||
                    activeSite.state.status !== 'deployed'
                  }
                  onClick={() => confirmSitePrepare(activeSite.id, sshSelected)} />
              </p>
              <p>
                <Button label="Bridge Site"
                  className="w-full md:w-14rem"
                  style={{ width: 150 }}
                  disabled={
                    activeSite.state.status !== 'running'
                  }
                  onClick={() => confirmSiteBridge(activeSite.id, siteSelected, sshSelected)} />
              </p>
              <p>
                <Button label="Destroy Site"
                  className="w-full md:w-14rem"
                  severity="danger"
                  style={{ width: 150 }}
                  disabled={
                    activeSite.state.status === 'initialized' ||
                    activeSite.state.location === 'remote' ||
                    activeSite.state.protected === true
                  }
                  onClick={() => confirmSiteDestroy(activeSite.id)} />
              </p>
            </body>
          </SplitterPanel>
          <SplitterPanel className="flex align-items-center justify-content-center">
            <body>
              <p>
                <Dropdown placeholder="Infra Credentials"
                  showClear
                  className="w-full md:w-14rem"
                  value={infraSelected} onChange={(e) => setInfraSelected(e.value)}
                  options={infraNames}
                  disabled={
                    activeSite.state.status !== 'initialized'
                  }
                />
              </p>
              <p>
                <Dropdown placeholder="SSH Credentials"
                  showClear
                  className="w-full md:w-14rem"
                  value={sshSelected} onChange={(e) => setSSHSelected(e.value)}
                  options={sshNames}
                  disabled={
                    activeSite.state.status === 'prepared' ||
                    activeSite.state.status !== 'deployed'
                  }
                />
              </p>
              <p>
                <Dropdown placeholder="Site 2 Name"
                  showClear
                  className="w-full md:w-14rem"
                  value={siteSelected} onChange={(e) => setSiteSelected(e.value)}
                  disabled={
                    activeSite.state.status !== 'running'
                  }
                  options={siteNames.filter(names => names !== activeSite.name)}
                />
              </p>
            </body>
          </SplitterPanel>
          <SplitterPanel className="flex align-items-center justify-content-center">
            <body>
              <p>
                <Dropdown placeholder="SSH Credentials"
                  showClear
                  className="w-full md:w-14rem"
                  value={sshSelected} onChange={(e) => setSSHSelected(e.value)}
                  options={sshNames}
                  disabled={
                    activeSite.state.status !== 'initialized'
                  }
                />
              </p>
            </body>
          </SplitterPanel>
        </Splitter>
      </Dialog>
    );
  };

  const infoButtonTemplate = (rowData) => (
    <>
      <Button text icon="pi pi-info-circle" size='small'
        className="p-button-info"
        severity="info"
        aria-label={rowData.name}
        tooltip="Info"
        tooltipOptions={{ position: "top" }}
        onClick={() => { setSiteInfoVisible(true); setActiveId(rowData.id); setActiveSite(rowData); setName(rowData.name) }} />
    </>
  )

  const configButtonTemplate = (rowData) => (
    <>
      <Button text icon="pi pi-wrench" size='small'
        className="p-button-success"
        severity="success"
        //disabled={
        //  rowData.state.location === "remote"
        //}
        aria-label={rowData.name}
        tooltip="Configure"
        tooltipOptions={{ position: "top" }}
        onClick={() => { setInfraSelected(null); setSSHSelected(null); setSiteSelected(null); setEditVisible(true); setActiveSite(rowData); setName(rowData.name) }} />
    </>
  )

  const deleteButtonTemplate = (rowData) => (
    <>
      <Button text icon="pi pi-trash" size='small'
        className="p-button-danger"
        severity="danger"
        tooltip="Delete"
        tooltipOptions={{ position: "top" }}
        onClick={() => { setForce(false); setSiteDeleteVisible(true); setActiveSite(rowData); setName(rowData.name) }} />
    </>
  )

  return (
    <React.Fragment>
      <ConfirmDialog />
      {createSiteDialog()}

      <Panel header="Sites">
        {displaySite()}
        {displayInfo()}
        {displayDelete()}
        <DataTable value={sites}
          size='small' stripedRows
          header={renderSiteHeader()}
          removableSort sortField="created" sortOrder={1}
          filters={{ global: siteFilter }}
          globalFilterFields={['name', 'description', 'site.type', 'created', 'state.location', 'state.status']}
          paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          tableStyle={{ minWidth: '50rem' }}>
          <Column field="name" sortable header="Name"></Column>
          <Column field="description" sortable header="Description"></Column>
          <Column field="site.type" sortable header="Type"></Column>
          <Column field="created" sortable header="Created"></Column>
          <Column field="state.location" sortable header="Location"></Column>
          <Column field="state.status" sortable header="Status"></Column>
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={(rowData: IAPISite) => (
              <Button text icon="pi pi-spin pi-spinner" size='small'
                visible={
                  tasksRunning[rowData.id]
                } />)}>
          </Column>
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={(rowData: IAPISite) => (
              <>
                {configButtonTemplate(rowData)}
              </>
            )}>
          </Column>
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={(rowData: IAPISite) => (
              <>
                {deleteButtonTemplate(rowData)}
              </>
            )}>
          </Column>
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={(rowData: IAPISite) => (
              <>
                {infoButtonTemplate(rowData)}
              </>
            )}>
          </Column>
        </DataTable>
      </Panel>
    </React.Fragment>
  )
};

export { Sites };
