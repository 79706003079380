const axios = require('axios').default;

import { IAPICredentialSSHRequest } from '@app/interfaces';
import { jwtDecode } from "jwt-decode";
import { useHistory } from 'react-router-dom';
import logout from '@app/Context/Auth';

interface axiosRequestConfig {
  baseURL: string,
  readonly url: string,
  readonly method: string,
  readonly data: any,
  headers: any,
  withCredentials: boolean,
  readonly validateStatus: (status: number) => boolean,
  params: any,
};

class BaseAPI {
  token: string | null = null;
  endpoint: string | null = null;

  #getHeaders(): Record<string, string | null> {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token}`;
    }

    return headers;
  }

  constructor(token: string | null) {
    this.token = token;
  }

  request(config: axiosRequestConfig): Promise<any> {
    config.baseURL = this.endpoint!;
    config.headers = config.headers || {};
    Object.assign(config.headers, this.#getHeaders());

    console.log({
      url: config.baseURL + config.url,
      headers: config.headers,
      data: config.data || {},
    }, 'api.request()');
    return axios(config);
  }
}

class AccelerateAPI extends BaseAPI {
  endpoint: string = window.location.origin;
  
  token: any = localStorage.getItem('token');
  // endpoint: string = 'http://localhost:3500';

  constructor(token: string | null) {
    super(token);

    console.debug('AccelerateAPI.endpoint', this.endpoint);
  }

  async login(username: string, password: string) {
    return axios(`${this.endpoint}/api/v1/auth/login`, {
      method: 'post',
      data: {
        username: username,
        password: password,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      validateStatus: (status: number) => {
        return status === 200;
      },
    });
  }

  async logout() {
    window.location.reload();
  }

  getSystemInfo() {
    return this.request({
      url: `/api/v1/system/info`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  getUser(user_id: string) {
    return this.request({
      url: `/api/v1/users/${user_id}`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  getUserSelf() {
    return this.request({
      url: `/api/v1/users/me`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  listUsers() {
    return this.request({
      url: `/api/v1/users`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  listSites() {
    return this.request({
      url: `/api/v1/sites`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  createSite(data: any) {
    return this.request({
      url: `/api/v1/sites`,
      method: 'post',
      data: data,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  createLocalSite(data: any) {
    return this.request({
      url: `/api/v1/sites?location=local`,
      method: 'post',
      data: data,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  createRemoteSite(data: any) {
    return this.request({
      url: `/api/v1/sites?location=remote`,
      method: 'post',
      data: data,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  getRemoteFile(id: string) {
    return this.request({
      url: `/api/v1/sites/${id}/remote`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  deploySite(id: string, infra_creds, ssh_creds) {
    return this.request({
      url: `/api/v1/sites/${id}/deploy`,
      method: 'post',
      data: {
        infra_credentials: infra_creds,
        ssh_credentials: ssh_creds
      },
      withCredentials: true,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  deleteSite(id: string, force: boolean) {
    return this.request({
      url: `/api/v1/sites/${id}?force=${force}`,
      method: 'delete',
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  destroySite(id: string) {
    return this.request({
      url: `/api/v1/sites/${id}/destroy`,
      method: 'post',
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  prepareSite(id: string, ssh) {
    return this.request({
      url: `/api/v1/sites/${id}/prepare`,
      method: 'post',
      data: {
        ssh_credentials: ssh
      },
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  bridgeSite(id: string, site2, ssh) {
    return this.request({
      url: `/api/v1/sites/${id}/bridge`,
      method: 'post',
      data: {
        site: site2,
        ssh_credentials: ssh
      },
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  listTasks(id: string) {
    return this.request({
      url: `/api/v1/sites/${id}/tasks`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  tasksRunning(id: string) {
    return this.request({
      url: `/api/v1/sites/${id}/tasks/running`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  listCredentialsSSH() {
    return this.request({
      url: `/api/v1/credentials/ssh`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  createCredentialSSH(data: IAPICredentialSSHRequest) {
    return this.request({
      url: `/api/v1/credentials/ssh`,
      method: 'post',
      data: data,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  deleteCredentialSSH(id: string) {
    return this.request({
      url: `/api/v1/credentials/ssh/${id}`,
      method: 'delete',
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  listCredentialsInfra() {
    return this.request({
      url: `/api/v1/credentials/infra`,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  createCredentialInfra(data: any) {
    return this.request({
      url: `/api/v1/credentials/infra`,
      method: 'post',
      data: data,
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  deleteCredentialInfra(id: string) {
    return this.request({
      url: `/api/v1/credentials/infra/${id}`,
      method: 'delete',
      validateStatus: (status: number) => {
        return status === 200;
      },
    } as unknown as axiosRequestConfig);
  }

  checkToken() {
    let decoded=jwtDecode(this.token)
    let expTime=decoded.exp
    if (expTime && (expTime < Date.now() / 1000)) {
      this.logout()
    }
  }
}

export {
  AccelerateAPI,
};
