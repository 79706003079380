import * as React from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import useAlert from '@app/Context/Alert';
import useAuth from '@app/Context/Auth';
import { AccelerateAPI } from "@app/utils/api";
import { IAPISystemInfo } from '@app/interfaces';

const Dashboard: React.FunctionComponent = () => {
  const { token } = useAuth();
  const { showAPIErrorAlert } = useAlert();
  const [sysinfo, setSysinfo] = React.useState<IAPISystemInfo>();
  const api = new AccelerateAPI(token!);

  // Initial load
  React.useEffect(() => {
    fetchSysinfo();
    setInterval(async () => api.checkToken(), 10000)
  }, [token]);

  function fetchSysinfo() {
    api.getSystemInfo().then(res => res.data).then(setSysinfo)
      .catch(err => showAPIErrorAlert(err, "Unable to fetch System Information"));
  }

  return (
    <div>
      <h1>Dashboard</h1>
      <DataTable value={sysinfo ? [sysinfo] : []} tableStyle={{ minWidth: '50rem' }}>
        <Column field="name" header="Name"></Column>
        <Column field="description" header="Description"></Column>
        <Column field="version" header="Version"></Column>
      </DataTable>
    </div>
  )
};

export { Dashboard };
