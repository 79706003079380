import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import { AccelerateAPI } from "@app/utils/api";

interface AuthContextType {
  token: string | null;
  saveSession: (token: string) => void;
  destroySession: () => void;
  logout: (redirect: boolean) => void;
}

const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [token, setToken] = useState<string | null>();
  const history = useHistory();

  function saveSession(token: string) {
    console.log('saveSession', token);
    setToken(token);
  }

  function destroySession() {
    console.log('destroySession');
    localStorage.clear();
    setToken(undefined);
  }

  // Fires the first time the provider is mounted only.
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token ) {
      logout(true);
      return;
    }

    const api = new AccelerateAPI(token);

    // Make an authenticated request to test token validity
    api.getUserSelf().then((response) => {
      setToken(token);
    }).catch((error) => {
      console.error('getSystemInfo', error);
      logout(true);
    });
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  // Call the logout endpoint and then remove the user
  // from the state.
  function logout(redirect: boolean = true) {
    destroySession();

    if( redirect ) {
      history.push("/login");
    }
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      token,
      saveSession,
      destroySession,
      logout,
    } as AuthContextType),
    [token]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
