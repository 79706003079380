import * as React from 'react';

import { useHistory } from 'react-router-dom';

const NotFound: React.FunctionComponent = () => {
  return (
    <div>
      Page Not Found
    </div>
  )
};

export { NotFound };
