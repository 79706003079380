import * as React from 'react';

import { Panel } from 'primereact/panel';
import { DataTable, DataTableFilterMetaData } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';

import useAlert from '@app/Context/Alert';
import useAuth from '@app/Context/Auth';
import { AccelerateAPI } from "@app/utils/api";
import {
  IAPICredentialSSH,
  IAPICredentialInfra,
  IAPICredentialSSHRequest
} from '@app/interfaces';
import { TabPanel, TabView } from 'primereact/tabview';


interface IFormSSH {
  name: string;
  username: string;
  privateKey: string;
}

interface IFormInfraAWS {
  secret_access_key: string;
  access_key_id: string;
}

interface IFormInfra {
  name: string;
  description: string;
  type: string;
  data: any | IFormInfraAWS;
}

const Credentials: React.FunctionComponent = () => {
  const { token } = useAuth();
  const { showAPIErrorAlert } = useAlert();
  const [sshCreds, setSSHCreds] = React.useState<IAPICredentialSSH[]>([]);
  const [infraCreds, setInfraCreds] = React.useState<IAPICredentialInfra[]>([]);
  const [sshFilter, setSSHFilter] = React.useState<DataTableFilterMetaData>({
    value: '',
    matchMode: FilterMatchMode.CONTAINS
  });
  const [sshFilterValue, setSSHFilterValue] = React.useState('');
  const [infraFilter, setInfraFilter] = React.useState<DataTableFilterMetaData>({
    value: '',
    matchMode: FilterMatchMode.CONTAINS
  });
  const [infraFilterValue, setInfraFilterValue] = React.useState('');
  const [sshImportVisible, setSSHImportVisible] = React.useState(false);
  const [infraImportVisible, setInfraImportVisible] = React.useState(false);
  const [sshFileImportVisible, setSSHFileImportVisible] = React.useState(false);
  const [infraFileImportVisible, setInfraFileImportVisible] = React.useState(false);

  const [sshVisible, setSSHVisible] = React.useState(false);
  const [activeSSH, setActiveSSH] = React.useState<IAPICredentialSSH>({
    id: "",
    owner: "",
    name: "",
    description: "",
    username: "",
    data: {
      type: ""
    },
    created: "",
    updated: ""
  });
  const [infraVisible, setInfraVisible] = React.useState(false)
  const [activeInfra, setActiveInfra] = React.useState<IAPICredentialInfra>({
    id: "",
    owner: "",
    name: "",
    description: "",
    data: {
      type: "",
      access_key_id: ""
    },
    created: "",
    updated: ""
  });

  const [formSSH, setFormSSH] = React.useState<IFormSSH>({
    name: '',
    username: '',
    privateKey: ''
  });
  const [formInfra, setFormInfra] = React.useState<IFormInfra>({
    name: '',
    description: '',
    type: '',
    data: {}
  });

  const api = new AccelerateAPI(token!);

  // Initial load
  React.useEffect(() => {
    fetchSites();
    setInterval(async () => api.checkToken(), 10000)
  }, [token]);

  function fetchSites() {
    api.listCredentialsSSH().then(res => res.data).then(setSSHCreds)
      .catch(err => showAPIErrorAlert(err, "Unable to fetch Credentials (SSH)"));
    api.listCredentialsInfra().then(res => res.data).then(setInfraCreds)
      .catch(err => showAPIErrorAlert(err, "Unable to fetch Credentials (Infra)"));
  }

  const setFormSSHValue = (key: string, value: any) => {
    setFormSSH({ ...formSSH, [key]: value });
  }

  const setFormInfraValue = (key: string, value: any) => {
    setFormInfra({ ...formInfra, [key]: value });
  }

  const setFormInfraDataValue = (key: string, value: any) => {
    setFormInfra({ ...formInfra, data: { ...formInfra.data, [key]: value } });
  }

  const onSSHFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSSHFilter({ ...sshFilter, value: value });
    setSSHFilterValue(value);
  };

  const onInfraFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInfraFilter({ ...infraFilter, value: value });
    setInfraFilterValue(value);
  };

  const renderSSHHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={sshFilterValue}
            onChange={onSSHFilterChange}
            placeholder="Keyword Search" />
        </span>

        <Button size='small'
          className="p-button-primary"
          onClick={() => setSSHImportVisible(true)}>
          Create
        </Button>
        <Button size='small'
          className="p-button-primary"
          onClick={() => setSSHFileImportVisible(true)}>
          Import
        </Button>
      </div>
    );
  };

  const renderInfraHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={infraFilterValue}
            onChange={onInfraFilterChange}
            placeholder="Keyword Search" />
        </span>

        <Button size='small'
          className="p-button-primary"
          onClick={() => setInfraImportVisible(true)}>
          Create
        </Button>
        <Button size='small'
          className="p-button-primary"
          onClick={() => setInfraFileImportVisible(true)}>
          Import
        </Button>
      </div>
    );
  };

  const confirmSSHDelete = (id: string) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => {
        api.deleteCredentialSSH(id).then(fetchSites)
          .catch(err => showAPIErrorAlert(err, "Unable to delete SSH Credential"));
      },
      reject: () => { }
    });
  };

  const confirmInfraDelete = (id: string) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => {
        api.deleteCredentialInfra(id).then(fetchSites)
          .catch(err => showAPIErrorAlert(err, "Unable to delete Infra Credential"));
      },
      reject: () => { }
    });
  };

  const createSSHCredential = () => {
    const data: IAPICredentialSSHRequest = {
      name: formSSH.name,
      username: formSSH.username,
      data: {
        type: 'key',
        private_key: btoa(formSSH.privateKey)
      }
    };
    api.createCredentialSSH(data).then(fetchSites)
      .catch(err => showAPIErrorAlert(err, "Unable to create SSH Credential"))
      .finally(() => setSSHImportVisible(false));
  }

  const createInfraCredential = () => {
    const data = {
      name: formInfra.name,
      description: formInfra.description,
      data: { ...formInfra.data, type: formInfra.type }
    };
    api.createCredentialInfra(data).then(fetchSites)
      .catch(err => showAPIErrorAlert(err, "Unable to create Infra Credential"))
      .finally(() => setInfraImportVisible(false));
  }

  const isFormSSHValid = () => {
    return (
      formSSH.name.length > 0 &&
      formSSH.username.length > 0 &&
      formSSH.privateKey.length > 0
    );
  }

  const isFormInfraValid = () => {
    return (
      formInfra.name.length > 0 &&
      formInfra.type.length > 0 &&
      Object.keys(formInfra.data).length > 0
    );
  }

  const customSSHFileUploader = async (event: FileUploadHandlerEvent) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();

    if (file.name.endsWith(".yaml")) {
      console.log("uploaded file is .yaml");

      reader.readAsText(file);

      reader.onloadend = function () {
        const yaml = require('yaml');
        const fileData = yaml.parse(reader.result);
        console.log('fileData', fileData);

        api.createCredentialSSH(fileData).then(fetchSites)
          .catch(err => showAPIErrorAlert(err, "Unable to create SSH Credential"))
          .finally(() => setSSHImportVisible(false));

        setSSHFileImportVisible(false);

      };

    } else if (file.name.endsWith(".json")) {
      console.log("uploaded file is .json");

      reader.readAsText(file);

      reader.onloadend = function () {
        const fileData = reader.result;
        console.log('fileData', fileData);

        if (typeof fileData === 'string') {
          console.log('object', JSON.parse(fileData));

          api.createCredentialSSH(JSON.parse(fileData)).then(fetchSites)
            .catch(err => showAPIErrorAlert(err, "Unable to create SSH Credential"))
            .finally(() => setSSHImportVisible(false));

          setSSHFileImportVisible(false);

        } else {
          console.error('Unable to parse base64 data');
        }
      };
    }
  };

  const customCredFileUploader = async (event: FileUploadHandlerEvent) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();

    if (file.name.endsWith(".yaml")) {
      console.log("uploaded file is .yaml");

      reader.readAsText(file);

      reader.onloadend = function () {
        const yaml = require('yaml');
        const fileData = yaml.parse(reader.result);
        console.log('fileData', fileData);

        api.createCredentialInfra(fileData).then(fetchSites)
          .catch(err => showAPIErrorAlert(err, "Unable to create Infra Credential"))
          .finally(() => setInfraImportVisible(false));

        setInfraFileImportVisible(false);

      };

    } else if (file.name.endsWith(".json")) {
      console.log("uploaded file is .json");

      reader.readAsText(file);

      reader.onloadend = function () {
        const fileData = reader.result;
        console.log('fileData', fileData);

        if (typeof fileData === 'string') {
          console.log('object', JSON.parse(fileData));

          api.createCredentialInfra(fileData).then(fetchSites)
            .catch(err => showAPIErrorAlert(err, "Unable to create Infra Credential"))
            .finally(() => setInfraImportVisible(false));

          setInfraFileImportVisible(false);

        } else {
          console.error('Unable to parse base64 data');
        }
      };
    }
  };

  const createSSSHFileDialog = () => {
    return (
      <Dialog visible={sshFileImportVisible} modal
        style={{ width: '50rem' }}
        footer={
          <div>
            <Button label="Cancel" icon="pi pi-times"
              onClick={() => setSSHFileImportVisible(false)}
              className="p-button-text" />
          </div>
        }
        onHide={() => setSSHFileImportVisible(false)}>
        <p className="m-0">
          Using this form, you can import SSH credentials into the system.
        </p>
        <br />

        <div className="p-inputgroup flex-1">
          <FileUpload
            mode="basic"
            name="demo[]"
            accept=".json, .yaml"
            maxFileSize={1000000}
            customUpload
            uploadHandler={customSSHFileUploader} />
        </div>
      </Dialog>
    )
  }

  const createSSHCredentialDialog = () => {
    return (
      <Dialog visible={sshImportVisible} modal
        style={{ width: '50rem' }}
        footer={
          <div>
            <Button label="Cancel" icon="pi pi-times"
              onClick={() => setSSHImportVisible(false)}
              className="p-button-text" />
            <Button label="Import" icon="pi pi-check"
              disabled={!isFormSSHValid()}
              onClick={createSSHCredential} />
          </div>
        }
        onHide={() => setSSHImportVisible(false)}>
        <p className="m-0">
          Using this form, you can import an SSH private key into the system.
          This will allow you to use the private key to authenticate with remote systems.
        </p>
        <br />
        <div className="p-inputgroup flex-1">
          <InputText
            value={formSSH.name}
            onChange={(e) => setFormSSHValue('name', e.target.value)}
            placeholder="Name" />
        </div>
        <div className="p-inputgroup flex-1">
          <InputText
            value={formSSH.username}
            onChange={(e) => setFormSSHValue('username', e.target.value)}
            placeholder="Username" />
        </div>
        <div className="p-inputgroup flex-1">
          <InputTextarea
            value={formSSH.privateKey}
            onChange={(e) => setFormSSHValue('privateKey', e.target.value)}
            placeholder="SSH Private Key" />
        </div>

      </Dialog>
    )
  }

  const infraTypes = [{
    name: 'AWS',
    code: 'aws'
  }, {
    name: 'Google Cloud',
    code: 'gcp'
  }, {
    name: 'Azure',
    code: 'azure'
  }];

  const infraAWSForm = () => {
    return (
      <React.Fragment>
        <div className="p-inputgroup flex-1">
          <InputText placeholder="AWS Access Key"
            value={formInfra.data.access_key_id}
            onChange={(e) => setFormInfraDataValue('access_key_id', e.target.value)} />
        </div>
        <div className="p-inputgroup flex-1">
          <InputText placeholder="AWS Secret Key"
            value={formInfra.data.secret_access_key}
            onChange={(e) => setFormInfraDataValue('secret_access_key', e.target.value)} />
        </div>
      </React.Fragment>
    )
  }

  const createInfraCredentialDialog = () => {
    return (
      <Dialog visible={infraImportVisible} modal
        style={{ width: '50rem' }}
        footer={
          <div>
            <Button label="Cancel" icon="pi pi-times"
              onClick={() => setInfraImportVisible(false)}
              className="p-button-text" />
            <Button label="Import" icon="pi pi-check"
              disabled={!isFormInfraValid()}
              onClick={createInfraCredential} />
          </div>
        }
        onHide={() => setInfraImportVisible(false)}>
        <p className="m-0">
          Using this form, you can import an infrastructure credential into the system.
          This will allow you to use the credential to authenticate with remote IaaS.
        </p>
        <br />
        <div className="p-inputgroup flex-1">
          <InputText placeholder="Name"
            value={formInfra.name}
            onChange={(e) => setFormInfraValue('name', e.target.value)} />
        </div>
        <div className="p-inputgroup flex-1">
          <InputText placeholder="Description"
            value={formInfra.description}
            onChange={(e) => setFormInfraValue('description', e.target.value)} />
        </div>
        <div className="p-inputgroup flex-1">
          <Dropdown
            id="infra-type"
            placeholder='Select Type'
            className="w-full"
            options={infraTypes}
            optionLabel="name"
            optionValue="code"
            value={formInfra.type}
            onChange={(e) => setFormInfraValue('type', e.value)} />
        </div>

        {formInfra.type === 'aws' && infraAWSForm()}
      </Dialog>
    )
  }

  const createInfraFileDialog = () => {
    return (
      <Dialog visible={infraFileImportVisible}
        modal
        style={{ width: '50rem' }}
        footer={
          <div>
            <Button label="Cancel" icon="pi pi-times"
              onClick={() => setInfraFileImportVisible(false)}
              className="p-button-text" />
          </div>
        }
        onHide={() => setInfraFileImportVisible(false)}>
        <p className="m-0">
          Using this form, you can import an infrastructure credential into the system.
          This will allow you to use the credential to authenticate with remote IaaS.
        </p>
        <br />
        <div className="p-inputgroup flex-1">
          <FileUpload
            mode="basic"
            name="demo[]"
            accept=".json, .yaml"
            maxFileSize={1000000}
            customUpload
            uploadHandler={customCredFileUploader} />
        </div>
      </Dialog>
    )
  }

  const sshInfo = () => {
    return (
      <Dialog
        visible={sshVisible}
        header={activeSSH.name}
        onHide={() => setSSHVisible(false)}>
        UUID: {activeSSH.id}
      </Dialog>
    );
  };

  const infraInfo = () => {
    const yaml = require('yaml')
    return (
      <Dialog
        modal
        style={{ width: '50rem', height: '25rem' }}
        visible={infraVisible}
        header={activeInfra.name}
        onHide={() => setInfraVisible(false)}>
        <TabView>
          <TabPanel header="Info">
            <p className="m-0">
              UUID: {activeInfra.id}
            </p>
            <p className="m-0">
              Created: {activeInfra.created}
            </p>
            <p className="m-0">
              Updated: {activeInfra.updated}
            </p>
          </TabPanel>
          <TabPanel header="Data">
            <pre>
              {yaml.stringify(activeInfra.data)}
            </pre>
          </TabPanel>
        </TabView>
      </Dialog>
    );
  };

  const infoButtonTemplateSSH = (rowData) => (
    <>
      <Button text icon="pi pi-info-circle" size='small'
        className="p-button-info"
        severity="info"
        aria-label={rowData.name}
        tooltip="Info"
        tooltipOptions={{ position: "top" }}
        onClick={() => { setActiveSSH(rowData); setSSHVisible(true) }} />
    </>
  )

  const infoButtonTemplateInfra = (rowData) => (
    <>
      <Button text icon="pi pi-info-circle" size='small'
        className="p-button-info"
        severity="info"
        aria-label={rowData.name}
        tooltip="Info"
        tooltipOptions={{ position: "top" }}
        onClick={() => { setActiveInfra(rowData); setInfraVisible(true) }} />
    </>
  )

  return (
    <React.Fragment>
      <ConfirmDialog />

      {createSSHCredentialDialog()}
      {createSSSHFileDialog()}
      {createInfraCredentialDialog()}
      {createInfraFileDialog()}

      <Panel header="SSH Credentials">
        {sshInfo()}
        {infraInfo()}
        <DataTable value={sshCreds}
          size='small' stripedRows
          header={renderSSHHeader()}
          removableSort sortField="name" sortOrder={1}
          filters={{ global: sshFilter }}
          globalFilterFields={['name', 'description', 'username', 'data.type']}
          paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          tableStyle={{ minWidth: '50rem' }}>
          <Column field="name" sortable header="Name"></Column>
          <Column field="description" sortable header="Description"></Column>
          <Column field="username" sortable header="Username"></Column>
          <Column field="data.type" sortable header="Type"></Column>
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={(rowData: IAPICredentialSSH) => (
              <Button text icon="pi pi-trash" size='small'
                className="p-button-danger"
                tooltip="Delete"
                tooltipOptions={{ position: "top" }}
                onClick={() => confirmSSHDelete(rowData.id)} />
            )} />
          <Column headerStyle={{ width: '0.5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={(rowData: IAPICredentialSSH) => (
              <>
                {infoButtonTemplateSSH(rowData)}
              </>
            )}></Column>
        </DataTable>
      </Panel>
      <Panel header="Infrastructure Credentials">
        <DataTable value={infraCreds}
          size='small' stripedRows
          header={renderInfraHeader()}
          removableSort sortField="name" sortOrder={1}
          filters={{ global: infraFilter }}
          globalFilterFields={['name', 'description', 'data.type']}
          paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          tableStyle={{ minWidth: '50rem' }}>
          <Column field="name" sortable header="Name"></Column>
          <Column field="description" sortable header="Description"></Column>
          <Column field="data.type" sortable header="Type"></Column>
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={(rowData: IAPICredentialInfra) => (
              <Button text icon="pi pi-trash" size='small'
                className="p-button-danger"
                tooltip="Delete"
                tooltipOptions={{ position: "top" }}
                onClick={() => confirmInfraDelete(rowData.id)} />
            )} />
          <Column headerStyle={{ width: '0.5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={(rowData: IAPICredentialInfra) => (
              <>
                {infoButtonTemplateInfra(rowData)}
              </>
            )}></Column>
        </DataTable>
      </Panel>
    </React.Fragment>
  )
};

export { Credentials };
