import React, { createContext, useState, useMemo } from 'react';
import { Toast } from 'primereact/toast';
import { ToastMessage } from 'primereact/toast';
import { AxiosError } from 'axios';

interface IAlert {
  severity: ToastMessage['severity'];
  timeout: number,
  title: string;
}

interface ContextProps {
  open: (alert: IAlert) => {},
  showAPIErrorAlert: (error: AxiosError | Error, message: string) => {},
  showAPISuccessAlert: (message: string) => {},
  showErrorAlert: (message: string) => {}
}

export const AlertContext = createContext<ContextProps>({
  open: (alert: IAlert) => { return {} as IAlert },
  showAPIErrorAlert: (error: AxiosError | Error, message: string) => { return {} as IAlert },
  showAPISuccessAlert: (message: string) => { return {} as IAlert },
  showErrorAlert: (message: string) => { return {} as IAlert }
});

export const AlertProvider = (props) => {
  const toast = React.useRef<Toast>(null)

  const open = (alert: IAlert) => {
    toast.current?.show({
      severity: alert.severity,
      summary: alert.title,
      life: alert.timeout,
    });

    return alert;
  }

  const showAPIErrorAlert = (error: AxiosError | Error, message: string) => {
    return open({
      severity: 'error',
      timeout: 10000,
      title: "(API) " + `${message}. ${error.message}.`
    });
  };

  const showAPISuccessAlert = (message: string) => {
    return open({
      severity: 'success',
      timeout: 5000,
      title: message + '.',
    });
  };

  const showErrorAlert = (message: string) => {
    return open({
      severity: 'error',
      timeout: 10000,
      title: message + '.',
    });
  };

  const contextValue = useMemo(() => ({
    open, showAPIErrorAlert, showAPISuccessAlert, showErrorAlert
  }), []);

  return (
    <AlertContext.Provider value={contextValue}>
      { props.children }
      <Toast ref={toast} />
    </AlertContext.Provider>
  )
};

// Let's only export the `useAlert` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAlert() {
  return React.useContext(AlertContext);
}
