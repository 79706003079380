import React, { useContext } from 'react';

import { FormEvent } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import useAuth from '@app/Context/Auth';
import useAlert from '@app/Context/Alert';

import { AccelerateAPI } from "@app/utils/api";
import { AxiosError } from 'axios';
import { useHistory } from "react-router-dom";

export const SimpleLogoutPage: React.FunctionComponent = () => {
  const { logout } = useAuth();

  React.useEffect(() => {
    logout(true);
  }, []);

  return (
    <span>Logging out...</span>
  );
};


export const SimpleLoginPage: React.FunctionComponent = () => {
  const [username, setUsername] = React.useState('');
  const [isValidUsername, setIsValidUsername] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [isValidPassword, setIsValidPassword] = React.useState(false);
  const { logout, saveSession } = useAuth();
  const { showAPIErrorAlert } = useAlert();
  const history = useHistory();

  const onLoginButtonClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    console.log('Logging in with', username, password);
    const api = new AccelerateAPI(null);
    api.login(username, password)
      .then(res => res.data.token)
      .then(token => {
        console.log('Login response', token);
        saveSession(token);
        history.push('/');
      })
      .catch((error: AxiosError) => {
        console.error('Login failed', error);
        showAPIErrorAlert(error, "Login failed");
        logout(false);
      });
  };

  React.useEffect(() => {
    setIsValidUsername(!!username);
    setIsValidPassword(!!password);
  }, [username, password]);

  return (
    <div className="grid" style={{marginTop: "10%"}}>
      <div className="col-6 col-offset-3">
        <Card title="Vcinity Accelerate" className="text-center p-3 border-round-sm">
          <form onSubmit={onLoginButtonClick}>
            <div className="loginContainer flex flex-column gap-2 p-4 justify-content-center align-items-left">
              <div className="align-items-left ">
                <h3>Sign in</h3>
              </div>
              <InputText placeholder="Username" style={{width: "100%"}} onChange={e => setUsername(e.target.value)} />
              <Password placeholder="Password" style={{width: "100%"}} inputStyle={{width: "100%"}} feedback={false} onChange={e => setPassword(e.target.value)} />
              <Button
                type="submit"
                disabled={!isValidUsername || !isValidPassword}>
                  Submit
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};
