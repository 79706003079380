import * as React from 'react';
import 'primereact/resources/themes/viva-dark/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppLayout } from '@app/AppLayout/AppLayout';
import { AppRoutes } from '@app/routes';
import { AlertProvider } from '@app/Context/Alert';
import { AuthProvider } from '@app/Context/Auth';
import '@app/app.css';

const App: React.FunctionComponent = () => (
  <Router>
    <AlertProvider>
      <AuthProvider>
        <AppLayout>
          <AppRoutes />
        </AppLayout>
      </AuthProvider>
    </AlertProvider>
  </Router>
);

export default App;
