import * as React from 'react';

import { Panel } from 'primereact/panel';
import { DataTable, DataTableFilterMetaData } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';

import useAlert from '@app/Context/Alert';
import useAuth from '@app/Context/Auth';
import { AccelerateAPI } from "@app/utils/api";
import { IAPIUser } from '@app/interfaces';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const AdminUsers: React.FunctionComponent = () => {
  const { token } = useAuth();
  const { showAPIErrorAlert } = useAlert();
  const [users, setUsers] = React.useState<IAPIUser[]>([]);
  const [userFilter, setUserFilter] = React.useState<DataTableFilterMetaData>({
    value: '',
    matchMode: FilterMatchMode.CONTAINS
  });
  const [userFilterValue, setUserFilterValue] = React.useState('');
  const api = new AccelerateAPI(token!);

  const [userVisible, setUserVisible] = React.useState(false);
  const [activeUser, setActiveUser] = React.useState<IAPIUser>({
    id: "",
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    is_locked: false,
    last_login: "",
    created: "",
    updated: "",
    roles: [],
    failed_login_attempts: 0,
    password_expires: "",
    password_updated: "",
    is_active: true,
  });

  // Initial load
  React.useEffect(() => {
    fetchUsers();
    setInterval(async () => api.checkToken(), 10000)
  }, [token]);

  function fetchUsers() {
    api.listUsers().then(res => res.data).then(setUsers)
      .catch(err => showAPIErrorAlert(err, "Unable to fetch Users"));
  }

  const onUserFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserFilter({ ...userFilter, value: value });
    setUserFilterValue(value);
  }

  const renderUserHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={userFilterValue}
            onChange={onUserFilterChange}
            placeholder="Keyword Search" />
        </span>
      </div>
    );
  }

  const userInfo = () => {
    const yaml = require('yaml')
    return (
      <Dialog
        modal
        style={{ width: '50rem', height: '25rem' }}
        visible={userVisible}
        header={activeUser.first_name}
        onHide={() => setUserVisible(false)}>
        <p className='m-0'>
          UUID: {activeUser.id}
        </p>
        <p className='m-0'>
          Created: {activeUser.created}
        </p>
        <p className='m-0'>
          Updated: {activeUser.updated}
        </p>
      </Dialog>
    )
  }

  const userButtonTemplate = (rowData) => (
    <>
      <Button text icon="pi pi-info-circle" size='small'
        className="p-button-info"
        severity="info"
        aria-label={rowData.name}
        tooltip="Info"
        tooltipOptions={{ position: "top" }}
        onClick={() => { setUserVisible(true); setActiveUser(rowData) }} />
    </>
  )

  return (
    <React.Fragment>
      {userInfo()}
      <Panel header="Users">
        <DataTable value={users}
          size='small' stripedRows
          header={renderUserHeader()}
          removableSort sortField="created" sortOrder={1}
          filters={{ global: userFilter }}
          globalFilterFields={['username', 'first_name', 'last_name']}
          paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          tableStyle={{ minWidth: '50rem' }}>
          <Column field="username" sortable header="Username"></Column>
          <Column field="first_name" sortable header="First Name"></Column>
          <Column field="last_name" sortable header="Last Name"></Column>
          <Column headerStyle={{ width: '0.5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={(rowData: IAPIUser) => (
              <>
                {userButtonTemplate(rowData)}
              </>
            )}>
          </Column>
        </DataTable>
      </Panel>
    </React.Fragment>
  )
};

export { AdminUsers };
