import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { IAppRoute, IAppRouteGroup, routes } from '@app/routes';
import useAuth from '@app/Context/Auth';

import logo from '@app/assets/logo-60.png';
import 'primeicons/primeicons.css';

import { Sidebar } from 'primereact/sidebar';
import { PanelMenu } from 'primereact/panelmenu';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

interface IAppLayout {
  children: React.ReactNode;
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  const { token } = useAuth();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  function isLoginPage() {
    return (useLocation().pathname) === '/login';
  }

  const renderNavItem = (route: IAppRoute, index: number) => {
    if( route.hidden ) { return {}; }
    return {
      label: route.label,
      icon: 'pi pi-fw pi-home',
      to: route.path,
      url: route.path,
    }
  }

  const renderNavGroup = (group: IAppRouteGroup, index: number) => {
    return {
      label: group.label,
      icon: 'pi pi-fw pi-home',
      items: group.routes.map(renderNavItem)
    }
  }

  const renderNav = () => {
    return routes.filter(route => {
      if( route.routes )
        return route.routes.filter(route => !route.hidden).length;
      return !route.hidden;
    }).map((route, idx) => {
      if( route.routes )
        return renderNavGroup(route, idx);
      return renderNavItem(route, idx);
    });
  };

  const startContent = (
    <React.Fragment>
      <Button text
        className="mr-2"
        icon="pi pi-bars"
        onClick={(event) => setSidebarOpen(!sidebarOpen)} />
      <img className="logo" height={32} src={logo} alt="Accelerate" />
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <Button text
        className="mr-2"
        icon="pi pi-user"
        onClick={(event) => setSidebarOpen(!sidebarOpen)}
        aria-controls="user_menu" />
    </React.Fragment>
  );
  
  return (
    <div className="App">
      {!isLoginPage() && (
        <React.Fragment>
          <Toolbar start={startContent} end={endContent} />
          <Sidebar visible={sidebarOpen} onHide={() => setSidebarOpen(false)}>
            <PanelMenu id="nav-primary-simple" model={renderNav()} />
          </Sidebar>
        </React.Fragment>
      )}

      <div id={'primary-app-container'} tabIndex={-1}>
        {/* Ensure the token is loaded before loading Components! */}
        {(token || isLoginPage()) ? children :  <div />}
      </div>
    </div>
  );
};

export { AppLayout };
