import * as React from 'react';
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import { Dashboard } from '@app/Dashboard/Dashboard';
import { Sites } from '@app/Sites/Sites';
import { Credentials } from '@app/Credentials/Credentials';
import { AdminUsers } from '@app/Administration/Users';
import { SimpleLoginPage, SimpleLogoutPage } from '@app/Login/Login';
import { NotFound } from '@app/NotFound/NotFound';

import { useDocumentTitle } from '@app/utils/useDocumentTitle';

let routeFocusTimer: number;
export interface IAppRoute {
  label?: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  exact?: boolean;
  path: string;
  title: string;
  routes?: undefined;
  key?: number;
  hidden: boolean;
  pathRegex?: string;
}

export interface IAppRouteGroup {
  label: string;
  routes: IAppRoute[];
}

export type AppRouteConfig = IAppRoute | IAppRouteGroup;

const routes: AppRouteConfig[] = [
  {
    component: Dashboard,
    exact: true,
    label: 'Dashboard',
    path: '/',
    title: 'Main Dashboard',
    hidden: false,
  },
  {
    component: Sites,
    exact: true,
    label: 'Sites',
    path: '/sites',
    title: 'Sites',
    hidden: false,
  },
  {
    component: Credentials,
    exact: true,
    label: 'Credentials',
    path: '/credentials',
    title: 'Credentials',
    hidden: false,
  },
  {
    label: 'Administration',
    routes: [
      {
        component: AdminUsers,
        exact: true,
        label: 'Users',
        path: '/admin/users',
        title: 'Users',
        hidden: false,
      },
    ],
  },
  {
    component: SimpleLoginPage,
    exact: true,
    label: 'Login',
    path: '/login',
    title: 'Login',
    hidden: true,
  },
  {
    component: SimpleLogoutPage,
    exact: true,
    label: 'Logout',
    path: '/logout',
    title: 'Logout',
    hidden: false,
  },
];

// a custom hook for sending focus to the primary content container
// after a view has loaded so that subsequent press of tab key
// sends focus directly to relevant content
// may not be necessary if https://github.com/ReactTraining/react-router/issues/5210 is resolved
const useA11yRouteChange = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    routeFocusTimer = window.setTimeout(() => {
      const mainContainer = document.getElementById('primary-app-container');
      if (mainContainer) {
        mainContainer.focus();
      }
    }, 50);
    return () => {
      window.clearTimeout(routeFocusTimer);
    };
  }, [pathname]);
};

const RouteWithTitleUpdates = ({ component: Component, title, ...rest }: IAppRoute) => {
  useA11yRouteChange();
  useDocumentTitle(title);

  function routeWithTitle(routeProps: RouteComponentProps) {
    return <Component {...rest} {...routeProps} />;
  }

  return <Route render={routeWithTitle} {...rest} />;
};

const PageNotFound = ({ title }: { title: string }) => {
  useDocumentTitle(title);
  return <Route component={NotFound} />;
};

const flattenedRoutes: IAppRoute[] = routes.reduce(
  (flattened, route) => [...flattened, ...(route.routes ? route.routes : [route])],
  [] as IAppRoute[]
);

const AppRoutes = (): React.ReactElement => (
  <Switch>
    {flattenedRoutes.map(({ path, exact, component, title, hidden }, idx) => (
      <RouteWithTitleUpdates
        key={idx}
        path={path}
        exact={exact}
        component={component}
        title={title}
        hidden={hidden} />
    ))}
    <PageNotFound title="404 Page Not Found" />
  </Switch>
);

export { AppRoutes, routes };
